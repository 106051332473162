<template>
    <v-dialog
        class="overflow-visible"
        no-click-animation
        max-width="300"
        v-model="show"
    >
        <close-button @click="closeDialog()" overflow/>
    <v-card
    class="elevation-16 mx-auto"
    width="300"
     >
        <v-card-title>
            <h4 class="text-grey">Your rating</h4>
        </v-card-title>
        <v-card-text>
        <div class="text-center">
            <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                hover
                large
            ></v-rating>
        </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                :loading="ratingState"
                text
                @click="submitRating"
            >
                Submit
            </v-btn>
        </v-card-actions>
   </v-card>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'RatingDialog',
    data () {
        return {
            show: false,
            rating: 0
        }
    },
    props: {
        plan: Object
    },
    computed: {
        ...mapState({
            ratingState: state => state.marketplace.status.ratingState
        })
    },
    methods: {
        ...mapActions('marketplace', [
            'ratePlan'
        ]),

        showDialog() {
            this.show = true
        },
        closeDialog() {
            this.show = false
        },

        submitRating () {
            Promise.resolve(this.ratePlan({ plan: this.plan, rating: this.rating}))
            .then(() => {
                this.closeDialog()
                this.$emit('refreshPlan', this.plan.id)
            })
        }
    }
}
</script>

<style>

</style>