<template>
    <v-dialog
        class="overflow-visible"
        no-click-animation
        max-width="400"
        persistent
        v-model="status.copyingPlan"
    >
        <v-card >
            <v-card-title>
            </v-card-title>
            <v-card-text class="text-center">
                Copying the selected plan please wait...
                <v-progress-linear
                    indeterminate
                    color="primary"
                    class="mb-0 mt-1"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'CopyPlanDialog',
    computed: {
        ...mapState({
            status: state => state.marketplace.status
        })
    },
}
</script>

<style>

</style>