<template>
 <v-dialog 
    class="overflow-visible"
    no-click-animation
    max-width="1000"
    v-model="show"
    persistent
    scrollable
 >
    <close-button @click="$emit('cancel')" overflow/>

    <v-card>
        <v-card-title :class="$classes.cardTitle">
            <span class="font-weight-bold headline">{{ plan.marketplaceName }}</span>
            
            <v-spacer></v-spacer>

            <v-btn 
            v-if="user.id !== plan.user && user.role !== 'admin'"
            color="primary" 
            class="mr-5"
            depressed
            small
            @click="$emit('copyPlan', plan)"
            >
            <v-icon left>mdi-plus-circle-outline</v-icon>
                Copy Mealplan
            </v-btn>
        </v-card-title>
        <v-card-text class="pt-5 pb-7">
            <div class="v-container">
                <v-row>
                    <v-col sm="12" md="5" lg="5" xl="5">
                        <div class="mb-5">
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Created by
                            </h5>
                            <p>
                                {{ getUser(plan.user) }}
                                <br />
                                {{ getUserRole(plan.user) }}
                            </p>
                        </div>
                        <div class="mb-5">
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Description
                            </h5>
                            <p class="pre-wrap">{{ plan.marketplaceDesc }}</p>
                        </div>
                        <div class="mb-5" >
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Ratings
                            </h5>
                            <marketplace-plan-rating :totalRatings="plan.totalRatings" :ratings="plan.ratings"  />
                            <v-btn
                                small
                                text
                                color="info"
                                v-if="!hasUserRated && !isPlanOwn"
                                @click="$emit('showRatingDialog')"
                            >
                                Rate Plan
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col sm="12" md="6" lg="6" xl="6">
                        <div class="mb-5" v-show="plan.mealTags" >
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Meal Tags
                            </h5>
                            <div>
                                <v-chip 
                                    v-for="(tag, i) in plan.mealTags" 
                                    :key="i" 
                                    small 
                                    label
                                    class="mr-1"
                                >
                                    {{ $store.getters['tags/getName'](tag) }}
                                </v-chip>
                            </div>
                        </div>
                        <div class="mb-5" v-show="plan.nutritionTags" >
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Nutrition Tags
                            </h5>
                            <div>
                                <v-chip 
                                    v-for="(tag, i) in plan.nutritionTags" 
                                    :key="i" 
                                    small 
                                    label
                                    class="mr-1"
                                >
                                    {{ $store.getters['nutritiontags/tagData'](tag).name }}
                                </v-chip>
                            </div>
                        </div>
                        <div class="mb-5" v-show="plan.downloads" >
                            <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                                Downloads
                            </h5>
                            <div >
                                {{ plan.downloads }}
                                <v-icon>mdi-account-outline</v-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div>
              <h5 class="mb-2 subtitle-1 font-weight-bold primary--text">
                Analysis Summary
              </h5>

              <v-card flat>
                <v-simple-table class="grey lighten-4">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th width="33%"></th>
                                <th width="33%">Average per week</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Weight</td>
                                <td>{{ plan.weight | amount | numberFormat }}(g)</td>
                            </tr>
                            <tr>
                                <td>Energy {{user.energyUnit == 'cal' ? '(Cal)' : '(kJ)'}}</td>

                                <td v-if="user.energyUnit =='cal'">{{ plan.energy | kjToCal | amount | numberFormat }} Cal</td>

                                <td v-else>{{ plan.energy }} kJ</td>
                            </tr>
                            <tr>
                                <td>Protein</td>
                                <td>{{ plan.protein }} (g)</td>
                            </tr>
                            <tr>
                                <td>Fat</td>
                                <td>{{ plan.fat }} (g)</td>
                            </tr>
                            <tr>
                                <td>Carbohydrate</td>
                                <td>{{ plan.carbohydrate }} (g)</td>
                            </tr>
                            <tr>
                                <td>Sugar</td>
                                <td>{{ plan.sugar }} (g)</td>
                            </tr>
                            <tr>
                                <td>Fibre</td>
                                <td>{{ plan.fibre }} (g)</td>
                            </tr>
                            <tr>
                                <td>Sodium</td>
                                <td>{{ plan.sodium }} (g)</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
              </v-card>
            </div>
        </v-card-text>
    </v-card>
 </v-dialog> 
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { startCase } from 'lodash'

import MarketplacePlanRating from '@/components/MarketplacePlanRating'

export default {
    name: 'SelectedPlan',
    props: {
        show: Boolean,
        plan: Object,
        role: String
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        ...mapGetters('members', [
            'getUser',
            'getRole'
        ]),
        hasUserRated () {
            if(!this.plan?.ratings)
                return false

            return this.plan.ratings.find(rating => rating.ratedBy === this.user.id)
        },
        isPlanOwn () {
            return this.plan.user === this.user.id
        }
    },
    components: {
        MarketplacePlanRating,
    },
    methods: {
        hasTags () {
            console.log(this.plan?.mealTags.length ? true : false)
            return this.plan?.mealTags.length ? true : false
        },
        getUserRole(id) {
            const role = this.getRole(id)

            return role === 'personal_trainer' ? 'Health & Fitness Professionals' : startCase(role)
        },
    },
    mounted() {
        if (!this.$store.state.nutritiontags.status.firstLoad) this.$store.dispatch('tags/getTags')
    }
}
</script>

<style>

</style>
CloseButton